
import Swiper from "swiper";
import 'swiper/swiper-bundle.css';
import {EffectCoverflow, EffectCreative, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

document.addEventListener('DOMContentLoaded', () => {
    let swiperActu;
    let swiperClient;

    function transformToSwiper(containerSelector) {
        const container = document.querySelector(containerSelector);
        const slides = Array.from(container.children);

        const swiperContainer = document.createElement('div');
        swiperContainer.classList.add('swiper-container');

        if (containerSelector.includes('bottom-container-actu')) {
            swiperContainer.classList.add('swiper-actus');
        } else if (containerSelector.includes('bottom-container-client')) {
            swiperContainer.classList.add('swiper-clients');
        }

        const swiperWrapper = document.createElement('div');
        swiperWrapper.classList.add('swiper-wrapper');

        slides.forEach(slide => {
            slide.classList.add('swiper-slide', 'slider-slide');
            swiperWrapper.appendChild(slide);
        });

        // Créer la navigation Swiper
        const navigationContainer = document.createElement('div');
        navigationContainer.classList.add('swiper-navigation-container');

        const prevButton = document.createElement('div');
        prevButton.classList.add('swiper-button-prev-custom');
        prevButton.innerHTML = `
        <svg width="59" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="36.1429" y="37.5" width="35.6429" height="37" rx="17.8214" transform="rotate(-180 36.1429 37.5)" stroke="#01FFCC"/>
            <mask id="mask0_5449_3049" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="6" width="25" height="25">
                <rect x="29.3143" y="30.3999" width="24" height="24" transform="rotate(-180 29.3143 30.3999)" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_5449_3049)">
                <path d="M13.6689 18.5249L24.7339 18.5249L24.7339 19.4749L13.6689 19.4749L18.9751 24.9775L18.3214 25.6499L11.9089 18.9999L18.3214 12.3499L18.9751 13.0223L13.6689 18.5249Z" fill="#001E2B"/>
            </g>
        </svg>`;
        navigationContainer.appendChild(prevButton);

        const pagination = document.createElement('div');
        pagination.classList.add('swiper-pagination');
        navigationContainer.appendChild(pagination);

        const nextButton = document.createElement('div');
        nextButton.classList.add('swiper-button-next-custom');
        nextButton.innerHTML = `
        <svg width="59" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.07147" y="0.5" width="37" height="37" rx="18.5" stroke="#01FFCC"/>
            <mask id="mask0_5449_3048" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="8" y="7" width="25" height="25">
                <rect x="8.17145" y="7.6001" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_5449_3048)">
                <path d="M24.3963 19.4751H12.9214V18.5251H24.3963L18.8936 13.0225L19.5714 12.3501L26.2214 19.0001L19.5714 25.6501L18.8936 24.9777L24.3963 19.4751Z" fill="#001E2B"/>
            </g>
        </svg>`;
        navigationContainer.appendChild(nextButton);

        container.innerHTML = '';
        container.appendChild(swiperContainer);

        swiperContainer.appendChild(navigationContainer);
        swiperContainer.appendChild(swiperWrapper);
    }

    function revertToOriginal(containerSelector, swiperClass) {
        const swiperContainer = document.querySelector(`.swiper-container.${swiperClass}`);
        if (!swiperContainer) {
            console.error('Swiper container not found');
            return;
        }

        const swiperWrapper = swiperContainer.querySelector('.swiper-wrapper');
        const container = document.querySelector(containerSelector);

        if (!container) {
            console.error('Target container not found');
            return;
        }

        const slides = Array.from(swiperWrapper.children);
        slides.forEach(slide => {
            slide.classList.remove('swiper-slide', 'slider-slide');
            container.appendChild(slide);
        });

        swiperContainer.remove();

        const navigationContainer = document.querySelector('.swiper-navigation-container');
        if (navigationContainer) {
            navigationContainer.remove();
        }
    }

    function swiperCard() {
        if (window.innerWidth <= 992 && !swiperActu && !swiperClient) {
            transformToSwiper('.nos-actualités .bottom-container-actu .items-container .item-container');

            swiperActu = new Swiper(".swiper-actus", {
                modules: [Navigation, Pagination, EffectCreative],
                spaceBetween: 10,
                slidesPerView: 1,
                loop: true,
                centeredSlides: true,
                navigation: {
                    nextEl: ".swiper-button-next-custom",
                    prevEl: ".swiper-button-prev-custom",
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "progressbar",
                },
                grabCursor: true,
                effect: "creative",
                speed: 1000,
                creativeEffect: {
                    prev: {
                        translate: ["-120%", 0, -500],
                    },
                    next: {
                        translate: ["120%", 0, -500],
                    },
                },
                breakpoints: {
                    992: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                }
            });

            transformToSwiper('.cas-client .bottom-container-client .items-container .item-container');

            swiperClient = new Swiper(".swiper-clients", {
                modules: [Navigation, Pagination, EffectCreative],
                spaceBetween: 10,
                slidesPerView: 1,
                loop: true,
                centeredSlides: true,
                navigation: {
                    nextEl: ".swiper-button-next-custom",
                    prevEl: ".swiper-button-prev-custom",
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "progressbar",
                },
                grabCursor: true,
                effect: "creative",
                speed: 1000,
                creativeEffect: {
                    prev: {
                        translate: ["-120%", 0, -500],
                    },
                    next: {
                        translate: ["120%", 0, -500],
                    },
                },
                breakpoints: {
                    992: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                }
            });
        } else if (window.innerWidth > 992 && swiperActu && swiperClient) {
            swiperActu.destroy(true, true);
            swiperActu = null;
            swiperClient.destroy(true, true);
            swiperClient = null;
            revertToOriginal('.bottom-container-actu .items-container .item-container', 'swiper-actus');
            revertToOriginal('.bottom-container-client .items-container .item-container', 'swiper-clients');
        }
    }

    window.addEventListener("resize", swiperCard);
});
